<template>
  <div class="px-7 py-8">
    <v-row no-gutters>
      <v-col cols="11" md="4" v-if="tab === 'pendingPrizeDistribution'">
        <v-text-field
          outlined
          dense
           v-model="prizesTableInfo[$API_KEYS.SEARCH]"
          label="Search by School"
          append-icon="mdi-magnify"
          type="text"
          color="#38227A"
          class="search-input"
          height="43px"
        >
          <template v-slot:label>
            <div style="font-size: 15px">Search by School</div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
        <v-row>
          <v-spacer></v-spacer>
            <v-col col-lg="3" class="pb-6 pr-4 text-right" v-if="this.tab === 'distributedPrizes'">
            <v-btn
              height="45"
              dark
              color="#38227A"
              style="border-radius: 10px"
              class="text-capitalize py-5 px-7"
              @click="
                toggleAddEditViewPrizeModal({
                  show: true,
                  type: 'add',
                  
                  tab: tab,
                })
              "
          >
              <span>Add new record</span>
            </v-btn>
          </v-col>
          <v-col col-lg="3" class="pb-6 pr-8 text-right" v-else-if="tab !== 'pendingPrizeDistribution' && this.adminAccess==='Super Admin'  ">
            <v-btn
              height="45"
              dark
              color="#38227A"
              style="border-radius: 10px"
              class="text-capitalize py-5 px-7"
              @click="
                toggleAddEditViewPrizeModal({
                  show: true,
                  type: 'add',
                  
                  tab: tab,
                })
              "
          v-if="$vuetify.breakpoint.smAndUp">
              <span>{{ buttonTitle }}</span>
            </v-btn>
             <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item   @click="
                toggleAddEditViewPrizeModal({
                  show: true,
                  type: 'add',
                  
                  tab: tab,
                })
              ">
                    <v-list-item-title>{{ buttonTitle }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="getTableHeaders"
      :items="items"
      :loading="loading"
      class="elevation-8"
      :options.sync="dataOptions"
      loading-text="Loading... Please wait"
      :items-per-page="
        prizesTableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
      "
      :server-items-length="
        prizesTableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
      "
      mobile-breakpoint="0"
    >
      <template v-slot:top v-if="tab !== 'pendingPrizeDistribution'">
    
      </template>
      <!-- item sections -->
      <template v-slot:item="props">
        <tr v-if="tab === 'prizeMaster'">
          <td class="text-center">
            <div class="itemTitle">{{ props.item.name }}</div>
          </td>
          <td class="text-center">
            <div class="itemTitle">{{ props.item.stats }}</div>
            <div style="font-size: 12px; color: #737373">Distributed</div>
          </td>
          <td>
            <div class="d-flex justify-center" style="width: 100%" v-if="props.item.user_type=='admin'">
              <v-switch
                color="#067605"
                v-model="props.item.status"
                @change="changeStatus(props.item.status, props.item.id)"
             >
                <template v-slot:label>
                  <div
                    :style="
                      props.item.status ? 'color:#067605' : 'color:#8C8C8C'
                    "
                  >
                    <div v-if="props.item.status">Active</div>
                    <div v-else>In-active</div>
                  </div>
                </template>
              </v-switch>
            </div>
          </td>
        </tr>
        <tr v-if="tab === 'commonPrizes' || tab === 'customPrizes'">
          <td class="text-center">
            <div style="display: flex" class="d-flex justify-center">
              <div style="float: left" class="py-4">
                <img :src="props.item.prize_logo" width="60" height="60" alt="" />
              </div>
              <div class="py-7 mx-2">
                <div class="schoolInfo">{{ props.item.prize }}</div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <div>{{ props.item.title }}</div>
          </td>
          <td class="text-center" v-if="tab === 'customPrizes'">
            <div class="schoolInfo">{{ props.item.school_name }}</div>
            <div class="districtInfo">{{ props.item.district_name }}</div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleDeleteModal({
                  Delete: true,
                  id: props.item.prize_id,
                  typeID: props.item.id,
                })
              "
           v-if="props.item.user_type=='admin'" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
        <tr v-if="tab === 'pendingPrizeDistribution'">
          <td class="text-center">
            <div class="justify-center">
              <div  class="py-2 px-2">
                <img :src="props.item.logo_url" width="60px" alt="" />
              </div>
              <div class="py-4 mx-2">
                <div class="schoolInfo">{{ props.item.school_name }}</div>
                <div class="districtInfo">{{ props.item.district_name }}</div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <div
              v-for="(item, i) in props.item.prize_list"
              :key="i"
              class="itemTitle"
            >
              {{ item.prize_name }} - {{ item.prize_count }}
            </div>
          </td>
          <td class="text-center">
            <v-chip
              v-if="props.item.status"
              :class="props.item.status.replace(' ', '', 'g').toLowerCase()"
            >
              {{ props.item.status }}
            </v-chip>
          </td>
          <td class="text-center">
            <!-- <v-chip class="actionColumn-btn" color="#F2F2F2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span>Download</span>
              </v-tooltip>
            </v-chip> -->
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="actionColumn-btn"
                  color="#F2F2F2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25" color="#6B6B6B">mdi-download</v-icon>
                </v-chip>
              </template>
              <v-list class="menu-list moreOptionsStyle py-0">
                <v-list-item-group class="py-0">
                  <v-list-item
                    style="min-height: 40px; border-top: 1px solid #d2d2d2"
                    @click="downloadReport('class', props.item)"
                  >
                    <v-list-item-title style="font-size: 14px">
                      Class Breakdown
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    style="min-height: 40px; border-top: 1px solid #d2d2d2"
                    @click="downloadReport('student', props.item)"
                  >
                    <v-list-item-title style="font-size: 14px">
                      Prize Summary
                    </v-list-item-title>
                  </v-list-item>
                    <v-list-item
                    style="min-height: 40px; border-top: 1px solid #d2d2d2"
                    @click="downloadStudentMedalReport(props.item)"
                  >
                    <v-list-item-title style="font-size: 14px">
                    Medal Report
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <tr v-if="tab === 'distributedPrizes'">
          <td class="text-center">
            <div >
              <div class="py-2 px-2">
                <img :src="props.item.logo_url" width="60px" alt="" />
              </div>
              <div class="py-5 mx-2">
                <div class="schoolInfo">{{ props.item.school_name }}</div>
                <div class="districtInfo">{{ props.item.district_name }}</div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <div
              v-for="(item, i) in props.item.prize_list"
              :key="i"
              class="itemTitle"
            >
              {{ item.prize_name }} - {{ item.prize_count }}
            </div>
          </td>
          <td class="text-center">
            <div v-if="props.item.date_time.date" style="width: 75px">
              {{ props.item.date_time.date }}
            </div>
            <div v-if="props.item.date_time.time">
              {{ props.item.date_time.time }}
            </div>
          </td>
          <td class="text-center">
            <v-row class="justify-center">
              <v-col class="pa-0 text-center" cols="12" xl="8">
                <v-chip
                  class="actionColumn-btn"
                  color="#F2F2F2"
                  @click="
                    toggleAddEditViewPrizeModal({
                      show: true,
                      type: 'edit',
                      tab: tab,
                      id: props.item.id,
                    })
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="icons"
                        color="#6B6B6B"
                        size="22"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </v-chip>
                <v-chip
                  class="actionColumn-btn"
                  color="#F2F2F2"
                  @click="
                    toggleDeleteModal({
                      Delete: true,
                      id: props.item.id,
                    })
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="icons"
                        color="#6B6B6B"
                        size="25"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-chip>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>

      <!-- item sections ends -->
      <template v-slot:footer>
        <v-divider></v-divider>
        <v-row no-gutters class="pt-6">
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPageList"
                  hide-details
                  v-model="
                    prizesTableInfo[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>
              <div class="alignSelf-center">
                {{
                  prizesTableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{ prizesTableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE] }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  prizesTableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  prizesTableInfo[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <add-edit-prize-modal @reload="changeTab(tab)"></add-edit-prize-modal>
    <delete-modal @Delete="prizeDeleteMethod()"></delete-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
//import _ from "lodash";
import {
  //   DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  DATA,
  SORTING_KEY,
  SEARCH,
} from "@/constants/APIKeys";
import {
  API_PRIZE_MASTER_GET,
  API_PRIZE_STATUS_PATCH,
  API_PRIZE_COMMON_CUSTOM_GET,
  API_PRIZE_DISTRIBUTED_GET,
  API_PRIZE_DELETE,
  API_PRIZE_DISTRIBUTION_DELETE,
  API_PENDING_PRIZE_REPORT,
  API_PENDING_PRIZE_STUDENT_MEDAL_REPORT
} from "@/constants/APIUrls";
export default {
  name: "PrizeManagement",
  components: {
    AddEditPrizeModal: () => import("./PrizeModal/PrizeModal"),
    DeleteModal: () => import("@/components/DeleteModal/DeleteModal"),
  },
  data() {
    return {
     adminAccess:localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      dataOptions1: { sortBy: [], sortDesc: [] },
      tab: "prizeMaster",
      loading: false,
      sortstatus: false,
      sortdate: false,
      search:"",
      items: [],
      prizesTableInfo: {
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 5,
          [ITEMS_PER_PAGE]: 10,
        },
      },
      itemsPerPageList: [10, 15, 20, 30, 50, 100],
      prizeMasterHeader: [
        {
          width: "25%",
          sortable: false,
          text: "Prize Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Stats",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
       
        {
          width: "30%",
          sortable: false,
          text: "Prize Status",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        
      ],
      commonPrizesHeader: [
        {
          width: "25%",
          sortable: false,
          text: "Prize",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Prize Type",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      customPrizesHeader: [
        {
          width: "25%",
          sortable: false,
          text: "Prize",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Prize Type",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "School",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      pendingPrizeDistributionHeader: [
        {
          width: "30%",
          sortable: false,
          text: "School",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Prizes",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      [SORTING_KEY]: "",
      [SEARCH]:"",
      distributedPrizesHeader: [
        {
          width: "30%",
          sortable: false,
          text: "School",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Prizes",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Date & Time",
          align: "center",
          value: "date",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {
    getTableHeaders() {
      let tab = "";
      if (this.tab === "prizeMaster") {
        tab = this.prizeMasterHeader;
      } else if (this.tab === "commonPrizes") {
        tab = this.commonPrizesHeader;
      } else if (this.tab === "customPrizes") {
        tab = this.customPrizesHeader;
      } else if (this.tab === "pendingPrizeDistribution") {
        tab = this.pendingPrizeDistributionHeader;
      } else if (this.tab === "distributedPrizes") {
        tab = this.distributedPrizesHeader;
      }
      return tab;
    },
    buttonTitle() {
      let title = "";
      if (this.tab === "prizeMaster") {
        title = "Add New Prize";
      } else if (this.tab === "commonPrizes") {
        title = "Add New Common Prize";
      } else if (this.tab === "customPrizes") {
        title = "Add New Current Prize";
      } 
      return title;
    },
  },
  watch: {
    "prizesTableInfo.page_info.page_number": function () {
      if (this.tab === "pendingPrizeDistribution") {
        this.distributedprizeGet("remaining");
      }
      if (this.tab === "distributedPrizes") {
        this.distributedprizeGet("distributed");
      }
    },
    "prizesTableInfo.search": function () {
      if (this.tab === "pendingPrizeDistribution") {
        this.distributedprizeGet("remaining");
        }
      },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "prizesTableInfo.page_info.detail_count": function () {
      //this.changeTab(this.tab);
      if (this.tab === "pendingPrizeDistribution") {
        this.distributedprizeGet("remaining");
      } else if (this.tab === "distributedPrizes") {
        this.distributedprizeGet("distributed");
      }
    },
    "dataOptions.sortBy": function () {
      if (this.tab === "distributedPrizes") {
        this.sortdate = true;
        if (this.dataOptions.sortBy.length !== 0) {
          this.prizesTableInfo[SORTING_KEY] = this.dataOptions.sortDesc[0]
            ? `-${this.dataOptions.sortBy[0]}`
            : this.dataOptions.sortBy[0];
          this.distributedprizeGet("distributed");
        }
      }
      if (this.tab === "pendingPrizeDistribution") {
        this.sortstatus = true;
        if (this.dataOptions.sortBy.length !== 0) {
          this.prizesTableInfo[SORTING_KEY] = this.dataOptions.sortDesc[0]
            ? `-${this.dataOptions.sortBy[0]}`
            : this.dataOptions.sortBy[0];
          this.distributedprizeGet("remaining");
        }
    
      }
     
    },
  },
  created() {
    this.$root.$refs.Prize = this;
     this.$root.$refs.donationManagement = this;
  //  this.debounceGetPendingPrizeDistributionData = _.debounce(
     //this.distributedprizeGet("remaining"),
   //   500
   // );
  },
  methods: {
    ...mapActions({
      toggleAddEditViewPrizeModal: "prizeManagement/toggleModal",
      toggleDeleteModal: "prizeManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
    }),
    changeTab(value) {
      this.tab = value;
      console.log(this.tab);
      this.pageDetailReset();
      switch (this.tab) {
        case "prizeMaster":
          this.getPrizeMaster();
          break;
        case "commonPrizes":
          this.getCommonCustomPrize("True");
          break;
        case "customPrizes":
          this.getCommonCustomPrize("False");
          break;
        case "pendingPrizeDistribution":
          this.distributedprizeGet("remaining");
          break;
        case "distributedPrizes":
          this.distributedprizeGet("distributed");
          break;
        default:
          console.log("do nothing");
          break;
      }
    },
    pageDetailReset() {
      this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER] = 1;
      this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE] = 10;
      this.prizesTableInfo[PAGE_INFO][TOTAL_PAGE] = 1;
      this.items = [];
    },
    getPrizeMaster() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data, "masterprize");
        this.items = res[DATA].prize_list;
        this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        this.prizesTableInfo[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData[ITEMS_PER_PAGE] =
        this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE];
      formData[PAGE_NUMBER] = this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER];
      Axios.request_GET(
        API_PRIZE_MASTER_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    changeStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = status;
      Axios.request_PATCH(
        API_PRIZE_STATUS_PATCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getCommonCustomPrize(is_common) {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res, "commonPrizeand");
        this.items = res[DATA].prize_type_list;
        this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        this.prizesTableInfo[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData[ITEMS_PER_PAGE] =
        this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE];
      formData[PAGE_NUMBER] = this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER];
      formData["is_common"] = is_common;
      Axios.request_GET(
        API_PRIZE_COMMON_CUSTOM_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    distributedprizeGet(response_type) {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.items = res.data.school_list;
        this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];

        this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        this.prizesTableInfo[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
         
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["response_type"] = response_type;
      formData[PAGE_NUMBER] = this.prizesTableInfo[PAGE_INFO][PAGE_NUMBER];
      formData[ITEMS_PER_PAGE] =
        this.prizesTableInfo[PAGE_INFO][ITEMS_PER_PAGE];
      if (this.sortstatus) {
        formData[SORTING_KEY] = this.prizesTableInfo[SORTING_KEY];
      }
      if (this.sortdate) {
        formData[SORTING_KEY] = this.prizesTableInfo[SORTING_KEY];
      }
        formData[SEARCH] = this.prizesTableInfo[SEARCH];
      Axios.request_GET(
        API_PRIZE_DISTRIBUTED_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    prizeDeleteMethod() {
      if (this.tab === "distributedPrizes") {
        this.distributionPrizeDelete();
      } else {
        this.prizeDelete();
      }
    },
    prizeDelete() {
      const successHandler = (res) => {
        console.log(res.data);
        this.changeTab(this.tab);
        this.showToast({
          message: "Prize deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["prize_id"] =
        this.$store.state.prizeManagement.deleteModal.prizeID;
      formData["prize_type"] =
        this.$store.state.prizeManagement.deleteModal.prizeTypeID;
      Axios.request_DELETE(
        API_PRIZE_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    distributionPrizeDelete() {
      const successHandler = (res) => {
        console.log(res.data);
        this.changeTab(this.tab);
        this.showToast({
          message: "Prize deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["id"] = this.$store.state.prizeManagement.deleteModal.prizeID;
      Axios.request_DELETE(
        API_PRIZE_DISTRIBUTION_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    downloadReport(type, item) {
      console.log(type, item);
      const successHandler = (res) => {
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = item.id;
      formData["report_type"] = type;
      Axios.request_GET_BLOB(
        API_PENDING_PRIZE_REPORT,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
     downloadStudentMedalReport(item) {
      const successHandler = (res) => {
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = item.id;
      Axios.request_GET_BLOB(
        API_PENDING_PRIZE_STUDENT_MEDAL_REPORT,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
  },
  mounted() {
    this.getPrizeMaster();
  },
  beforeDestroy() {
    this.$root.$refs.adminLayout.resetPrizeTab();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.itemTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 161.5%;
  width: 200px;
  text-align: center;
  color: #1d1d1d;
}
.schoolInfo {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 25px */
  color: #1d1d1d;
  margin-right: 5px;
}
.districtInfo {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 145%;
  /* or 23px */
  color: rgba(29, 29, 29, 0.6);
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  line-height: 161.5%;
}
.alignSelf-center {
  align-self: center;
}
.pagination {
  float: right;
}
.v-data-table.elevation-8.v-data-table--fixed-header.theme--light {
  border-radius: 10px;
}
span.v-chip {
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
}
span.v-chip.distributionpending {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 45px;
}
span.v-chip.partiallydistributed {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 45px;
}
span.v-chip.fullydistributed {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 45px;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text.tableHeader-bg span{
    margin-left: 25px;
  }
  .schoolInfo {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 145%;
    /* identical to box height, or 25px */
    color: #1d1d1d;
    margin-right: 5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
 